import React from 'react';
import Headline from 'headline';
import s from './stt.module.scss';

export default function SimpleTitleSubtitle({
  title,
  subtitle,
  className,
  titleClassName,
  subtitleClassName,
  invert,
  ...props
}) {
  return (
    <div className={`${s.root} ${invert ? s.invert : ''} ${className || ''}`}>
      <Headline h1 wild secondary className={`${s.title} ${titleClassName}`} html={title} />
      <div className={s.subtitleWrapper}>
        <Headline h2 className={`${s.subtitle} ${subtitleClassName}`} html={subtitle} />
      </div>
    </div>
  );
}
