import React, { useState, useEffect } from 'react';
import Icon from 'icon';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import { navigate } from 'gatsby';
import s from './back-arrow.module.scss';

export default function BackArrow(props) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 5000);
  }, []);
  return (
    <div className={`${s.root} ${show ? s.show : ''}`}>
      <div
        className={s.back}
        onClick={() => window.history.back()}
      >
        <Icon icon="slider_arrow" />
      </div>
      <div className={s.text}>
        <SimpleTitleSubtitle
          titleClassName={s.title}
          subtitleClassName={s.subtitle}
          primary
          title="Join Now"
          subtitle="THE CLOCK IS TICKING"
        />
      </div>
    </div>
  );
}
