import React from 'react';
import Tos from 'components/terms-and-conditions';

export default function TermsOfSevicePage() {
  return (
    <main>
      <Tos />
    </main>
  );
}
