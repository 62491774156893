import React from 'react';
import Container from 'container';
import Headline from 'headline';
import BackArrow from 'shared/back-arrow';
import s from './terms-and-conditions.module.scss';

export default function () {
  return (
    <Container className={s.root}>
      <BackArrow />
      <Headline h1 wild center primary className={s.title}>
        Terms and Conditions
      </Headline>
      <p>Date Effective: January, 2016</p>
      <p className={s.mb}>[Updated October 30, 2017]</p>
      <h2>1. General</h2>
      <p>
        This website (the “laurieanne.com”) is owned and operated by LA King,
        LLC (“we” or “us”). By using the Site, you agree to be bound by these
        Terms of Service and to use the Site in accordance with these Terms of
        Service, our Privacy Policy and any additional terms and conditions that
        may apply to specific sections of the Site or to products and services
        available through the Site or from LA King LLC. Accessing the Site, in
        any manner, whether automated or otherwise, constitutes use of the Site
        and your agreement to be bound by these Terms of Service.
      </p>
      <p>
        We reserve the right to change these Terms of Service or to impose new
        conditions on use of the Site, from time to time, in which case we will
        post the revised Terms of Service on this website. By continuing to use
        the Site after we post any such changes, you accept the Terms of
        Service, as modified.
      </p>
      <h2>2. Intellectual Property Rights</h2>
      <p>
        Our Limited License to You. This Site and all the materials available on
        the Site are the property of us and/or our affiliates or licensors, and
        are protected by copyright, trademark, and other intellectual property
        laws. The Site is provided solely for your personal noncommercial use.
        You may not use the Site or the materials available on the Site in a
        manner that constitutes an infringement of our rights or that has not
        been authorized by us. More specifically, unless explicitly authorized
        in these Terms of Service or by the owner of the materials, you may not
        modify, copy, reproduce, republish, upload, post, transmit, translate,
        sell, create derivative works, exploit, or distribute in any manner or
        medium (including by email or other electronic means) any material from
        the Site. You may, however, from time to time, download and/or print one
        copy of individual pages of the Site for your personal, non-commercial
        use, provided that you keep intact all copyright and other proprietary
        notices.
      </p>
      <p>
        Your License to Us. By posting or submitting any material (including,
        without limitation, comments, blog entries, Facebook postings, photos,
        videos, or voice recordings) to us via the Site, internet groups, social
        media venues, or to any of our staff via email, text or otherwise, you
        are representing: (i) that you are the owner of the material, or are
        making your posting or submission with the express consent of the owner
        of the material; and (ii) that you are thirteen years of age or older.
        In addition, when you submit, email, text or deliver or post any
        material, you are granting us, and anyone authorized by us, a
        royalty-free, perpetual, irrevocable, non-exclusive, unrestricted,
        worldwide license to use, copy, modify, transmit, sell, exploit, create
        derivative works from, distribute, and/or publicly perform or display
        such material, in whole or in part, in any manner or medium, now known
        or hereafter developed, for any purpose. The foregoing grant shall
        include the right to exploit any proprietary rights in such posting or
        submission, including, but not limited to, rights under copyright,
        trademark, service mark or patent laws under any relevant jurisdiction.
        Also, in connection with the exercise of such rights, you grant us, and
        anyone authorized by us, the right to identify you as the author of any
        of your postings or submissions by name, email address or screen name,
        as we deem appropriate.
      </p>
      <p>
        You acknowledge and agree that any contributions originally created by
        you for us shall be deemed a “work made for hire” when the work
        performed is within the scope of the definition of a work made for hire
        in Section 101 of the United States Copyright Law, as amended. As such,
        the copyrights in those works shall belong to LA King LLC from their
        creation. Thus, LA King LLC shall be deemed the author and exclusive
        owner thereof and shall have the right to exploit any or all of the
        results and proceeds in any and all media, now known or hereafter
        devised, throughout the universe, in perpetuity, in all languages, as LA
        King LLC determines. In the event that any of the results and proceeds
        of your submissions hereunder are not deemed a “work made for hire”
        under Section 101 of the Copyright Act, as amended, you hereby, without
        additional compensation, irrevocably assign, convey and transfer to LA
        King LLC all proprietary rights, including without limitation, all
        copyrights and trademarks throughout the universe, in perpetuity in
        every medium, whether now known or hereafter devised, to such material
        and any and all right, title and interest in and to all such proprietary
        rights in every medium, whether now known or hereafter devised,
        throughout the universe, in perpetuity. Any posted material which are
        reproductions of prior works by you shall be co-owned by us.
      </p>
      <p>
        You acknowledge that LA King LLC has the right but not the obligation to
        use and display any postings or contributions of any kind and that LA
        King LLC may elect to cease the use and display of any such materials
        (or any portion thereof), at any time for any reason whatsoever.
      </p>
      <p>
        Limitations on Linking and Framing. You may establish a hypertext link
        to the Site so long as the link does not state or imply any sponsorship
        of your site by us or by the Site. However, you may not, without our
        prior written permission, frame or inline link any of the content of the
        Site, or incorporate into another website or other service any of our
        material, content or intellectual property.
      </p>
      <h2>3. Disclaimers</h2>
      <p>
        Throughout the Site, we may provide links and pointers to Internet sites
        maintained by third parties. Our linking to such third-party sites does
        not imply an endorsement or sponsorship of such sites, or the
        information, products or services offered on or through the sites. In
        addition, neither we nor affiliates operate or control in any respect
        any information, products or services that third parties may provide on
        or through the Site or on websites linked to by us on the Site.
      </p>
      <p>
        If applicable, any opinions, advice, statements, services, offers, or
        other information or content expressed or made available by third
        parties, including information providers and laboratory results, are
        those of the respective authors or distributors, and not LA King LLC.
        Neither LA King LLC nor any third-party provider of information
        guarantees the accuracy, completeness, or usefulness of any content.
        Furthermore, LA King LLC neither endorses nor is responsible for the
        accuracy and reliability of any opinion, advice, or statement made on
        any of the Sites by anyone other than an authorized LA King LLC
        representative while acting in his/her official capacity.
      </p>
      <p>
        THE INFORMATION, PRODUCTS AND SERVICES OFFERED ON OR THROUGH THE SITE
        AND BY CN AND ANY THIRD-PARTY SITES ARE PROVIDED “AS IS” AND WITHOUT
        WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
        PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT
        THAT THE SITE OR ANY OF ITS FUNCTIONS WILL BE UNINTERRUPTED OR
        ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF THIS
        SITE, INCLUDING BULLETIN BOARDS, OR THE SERVERS THAT MAKE IT AVAILABLE,
        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
      </p>
      <p>
        WE BELIEVE THAT THE INFORMATION WE PROVIDE, INCLUDING THAT ON OUR SITES,
        BROCHURES, FLYERS AND INFORMATION PACKETS, IS ACCURATE, BUT WE DO NOT
        WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF
        THE USE OF THE SITE OR MATERIALS ON THIS SITE OR ON THIRD-PARTY SITES IN
        TERMS OF THEIR CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR
        OTHERWISE. IT IS YOUR RESPONSIBILITY TO VERIFY SUCH MATTERS
        INDEPENDENTLY FROM PRIMARY SOURCES OF INFORMATION AND BY TAKING SPECIFIC
        PROFESSIONAL ADVICE.
      </p>
      <p>
        You agree at all times to defend, indemnify and hold harmless LA King
        LLC, its affiliates, their successors, transferees, assignees and
        licensees and their respective parent and subsidiary companies, agents,
        associates, officers, directors, shareholders and employees of each from
        and against any and all claims, causes of action, damages, liabilities,
        costs and expenses, including legal fees and expenses, arising out of or
        related to your breach of any obligation, warranty, representation or
        covenant set forth herein.
      </p>
      <h2>4. Online Commerce</h2>
      <p>
        Certain sections of the Site may allow you to purchase many different
        types of products and services online that are provided by third
        parties. We are not responsible for the quality, accuracy, timeliness,
        reliability or any other aspect of these products and services. If you
        make a purchase from a merchant on the Site or on a site linked to by
        the Site, the information obtained during your visit to that merchant’s
        online store or site, and the information that you give as part of the
        transaction, such as your credit card number and contact information,
        may be collected by both the merchant and us. A merchant may have
        privacy and data collection practices that are different from ours. We
        have no responsibility or liability for these independent policies. In
        addition, when you purchase products or services on or through the Site,
        you may be subject to additional terms and conditions that specifically
        apply to your purchase or use of such products or services. For more
        information regarding a merchant, its online store, its privacy
        policies, and/or any additional terms and conditions that may apply,
        visit that merchant’s website and click on its information links or
        contact the merchant directly. You release us and our affiliates from
        any damages that you incur, and agree not to assert any claims against
        us or them, arising from your purchase or use of any products or
        services made available by third parties through the Site.
      </p>
      <p>
        Your participation, correspondence or business dealings with any third
        party found on or through our Site, regarding payment and delivery of
        specific goods and services, and any other terms, conditions,
        representations or warranties associated with such dealings, are solely
        between you and such third party. You agree that LA King LLC shall not
        be responsible or liable for any loss, damage, or other matters of any
        sort incurred as the result of such dealings.
      </p>
      <p>
        You agree to be financially responsible for all purchases made by you or
        someone acting on your behalf through the Site. You agree to use the
        Site and to purchase services or products through the Site for
        legitimate, non-commercial purposes only. You also agree not to make any
        purchases for speculative, false or fraudulent purposes or for the
        purpose of anticipating demand for a particular product or service. You
        agree to only purchase goods or services for yourself or for another
        person for whom you are legally permitted to do so. When making a
        purchase for a third party that requires you to submit the third party’s
        personal information to us or a merchant, you represent that you have
        obtained the express consent of such third party to provide such third
        party’s personal information.
      </p>
      <h2>5. Interactive Features</h2>
      <p>
        This Site may include a variety of features, such as bulletin boards,
        web logs, chat rooms, and email services, which allow feedback to us and
        real-time interaction between users, and other features which allow
        users to communicate with others. Responsibility for what is posted on
        bulletin boards, web logs, chat rooms, and other public posting areas on
        the Site, or sent via any email services on the Site, lies with each
        user – you alone are responsible for the material you post or send. We
        do not control the messages, information or files that you or others may
        provide through the Site. It is a condition of your use of the Site that
        you do not:
      </p>
      <p>
        Restrict or inhibit any other user from using and enjoying the Site.
      </p>
      <p>
        Use the Site to impersonate any person or entity, or falsely state or
        otherwise misrepresent your affiliation with a person or entity.
      </p>
      <p>
        Interfere with or disrupt any servers or networks used to provide the
        Site or its features, or disobey any requirements, procedures, policies
        or regulations of the networks we use to provide the Site.
      </p>
      <p>
        Use the Site to instigate or encourage others to commit illegal
        activities or cause injury or property damage to any person.
      </p>
      <p>
        Gain unauthorized access to the Site, or any account, computer system,
        or network connected to this Site, by means such as hacking, password
        mining or other illicit means.
      </p>
      <p>
        Obtain or attempt to obtain any materials or information through any
        means not intentionally made available through this Site.
      </p>
      <p>
        Use the Site to post or transmit any unlawful, threatening, abusive,
        libelous, defamatory, obscene, vulgar, pornographic, profane or indecent
        information of any kind, including without limitation any transmissions
        constituting or encouraging conduct that would constitute a criminal
        offense, give rise to civil liability or otherwise violate any local,
        state, national or international law.
      </p>
      <p>
        Use the Site to post or transmit any information, software or other
        material that violates or infringes upon the rights of others, including
        material that is an invasion of privacy or publicity rights or that is
        protected by copyright, trademark or other proprietary right, or
        derivative works with respect thereto, without first obtaining
        permission from the owner or rights holder.
      </p>
      <p>
        Use the Site to post or transmit any information, software or other
        material that contains a virus or other harmful component.
      </p>
      <p>
        Use the Site to post, transmit or in any way exploit any information,
        software or other material for commercial purposes, or that contains
        advertising.
      </p>
      <p>
        Use the Site to advertise or solicit to anyone to buy or sell products
        or services, or to make donations of any kind, without our express
        written approval.
      </p>
      <p>
        Gather for marketing purposes any email addresses or other personal
        information that has been posted by other users of the Site.
      </p>
      <p>
        LA King LLC may host message boards, chats and other public forums on
        its Site. Any user failing to comply with the terms and conditions of
        this Agreement may be expelled from and refused continued access to, the
        message boards, chats or other public forums in the future. LA King LLC
        or its designated agents may remove or alter any user-created content at
        any time for any reason. Message boards, chats and other public forums
        are intended to serve as discussion centers for users and subscribers.
        Information and content posted within these public forums may be
        provided by LA King LLC staff, LA King LLC’s outside contributors, or by
        users not connected with LA King LLC some of whom may employ anonymous
        user names. LA King LLC expressly disclaims all responsibility and
        endorsement and makes no representation as to the validity of any
        opinion, advice, information or statement made or displayed in these
        forums by third parties, nor are we responsible for any errors or
        omissions in such postings, or for hyperlinks embedded in any messages.
        Under no circumstances will we, our affiliates, suppliers or agents be
        liable for any loss or damage caused by your reliance on information
        obtained through these forums. The opinions expressed in these forums
        are solely the opinions of the participants, and do not reflect the
        opinions of LA King LLC or any of its subsidiaries or affiliates.
      </p>
      <p>
        LA King LLC has no obligation whatsoever to monitor any of the content
        or postings on the message boards, chat rooms or other public forums on
        the Sites. However, you acknowledge and agree that we have the absolute
        right to monitor the same at our sole discretion. In addition, we
        reserve the right to alter, edit, refuse to post or remove any postings
        or content, in whole or in part, for any reason and to disclose such
        materials and the circumstances surrounding their transmission to any
        third party in order to satisfy any applicable law, regulation, legal
        process or governmental request and to protect ourselves, our clients,
        sponsors, users and visitors.
      </p>
      <h2>6. Registration</h2>
      <p>
        To access certain features of the Site, we may ask you to provide
        certain demographic information including your gender, year of birth,
        zip code and country. In addition, if you elect to sign-up for a
        particular feature of the Site, such as chat rooms, web logs, or
        bulletin boards, you may also be asked to register with us on the form
        provided and such registration may require you to provide personally
        identifiable information such as your name and email address. You agree
        to provide true, accurate, current and complete information about
        yourself as prompted by the Site’s registration form. If we have
        reasonable grounds to suspect that such information is untrue,
        inaccurate, or incomplete, we have the right to suspend or terminate
        your account and refuse any and all current or future use of the Site
        (or any portion thereof). Our use of any personally identifiable
        information you provide to us as part of the registration process is
        governed by the terms of our Privacy Policy.
      </p>
      <h2>7. Passwords</h2>
      <p>
        To use certain features of the Site, you will need a username and
        password, which you will receive through the Site’s registration
        process. You are responsible for maintaining the confidentiality of the
        password and account, and are responsible for all activities (whether by
        you or by others) that occur under your password or account. You agree
        to notify us immediately of any unauthorized use of your password or
        account or any other breach of security, and to ensure that you exit
        from your account at the end of each session. We cannot and will not be
        liable for any loss or damage arising from your failure to protect your
        password or account information.
      </p>
      <h2>8. Limitation of Liability</h2>
      <p>
        UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL
        WE, OUR SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES BE LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT
        RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SITE, INCLUDING OUR
        MESSAGING, BLOGS, COMMENTS OF OTHERS, BOOKS, EMAILS, , TREATMENTS,
        PRODUCTS, OR SERVICES, OR THIRD-PARTY MATERIALS, PRODUCTS, OR SERVICES
        MADE AVAILABLE THROUGH THE SITE OR BY US IN ANY WAY, EVEN IF WE ARE
        ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME
        STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF
        DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, OUR
        LIABILITY AND THE LIABILITY OF OUR SUBSIDIARY AND PARENT COMPANIES OR
        AFFILIATES IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE
        LAW.) YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR
        ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER. IF YOU ARE
        DISSATISFIED WITH THE SITE, ANY MATERIALS, PRODUCTS, OR SERVICES ON THE
        SITE, OR WITH ANY OF THE SITE’S TERMS AND CONDITIONS, YOUR SOLE AND
        EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE AND THE PRODUCTS,
        SERVICES AND/OR MATERIALS.
      </p>
      <p>
        THIS SITE IS CONTINUALLY UNDER DEVELOPMENT AND LA King LLC MAKES NO
        WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY,
        COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE.
      </p>
      <p>
        PRODUCT & SERVICES DISCLAIMER – WITH REGARDS TO CONTENT RELATING TO
        HEALTH & HEALING PROGRAMS ON THE SITE:
      </p>
      <p>
        LA King LLC OFFERS HEALTH, HEALING AND WELLNESS INFORMATION AND IS
        DESIGNED FOR EDUCATIONAL PURPOSES ONLY. BY ASKING FOR OUR ADVICE AND
        SERVICES, AND/OR REQUESTING US TO PROVIDE ADVICE AND SERVICES TO YOU,
        YOU ACKNOWLEDGE AND ACCEPT THAT WE:
      </p>
      <p>ARE NOT MEDICALLY QUALIFIED;</p>
      <p>
        ARE NOT A LICENSED PHYSICIAN OR A SUBSTITUTE FOR ADVICE FROM A QUALIFIED
        DOCTOR;
      </p>
      <p>DO NOT WARRANT THE ACCURACY OF ANY INFORMATION PROVIDED;</p>
      <p>DO NOT DISPENSE OR PRESCRIBE ANY PRESCRIPTION PRODUCTS;</p>
      <p>
        HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION IN
        CONNECTION WITH ANY STATEMENTS MADE ON OUR SITE AND THAT THE SERVICES WE
        PROVIDE ARE NOT LICENSED BY THE STATE OF CALIFORNIA;
      </p>
      <p>
        ARE NOT LIABLE FOR ANY LOSSES YOU MAY SUFFER BY RELYING ON OUR ADVICE;
      </p>
      <p>DO NOT RECOMMEND OR ENDORSE ANY PARTICULAR PRODUCT;</p>
      <p>
        DO NOT ACCEPT ANY LIABILITY FOR ANY FAILURE TO IDENTIFY AND MEDICAL
        CONDITION OR DISEASE.
      </p>
      <p>
        IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU SHOULD
        ALWAYS CONSULT WITH A PHYSICIAN OR OTHER HEALTH-CARE PROFESSIONAL.
      </p>
      <p>
        DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED
        ADVICE FROM YOUR HEALTH-CARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY
        HAVE READ ON THIS SITE. CONSULT WITH YOUR OWN MEDICAL.
      </p>
      <p>
        THIS PROGRAM IS DESIGNED FOR EDUCATIONAL PURPOSES ONLY. YOU SHOULD NOT
        RELY ON THIS INFORMATION AS A SUBSTITUTE FOR, NOR DOES IT REPLACE,
        PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE ANY
        CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU SHOULD ALWAYS CONSULT WITH
        A PHYSICIAN OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT DISREGARD, AVOID
        OR DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE FROM YOUR
        HEALTH-CARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY HAVE READ HEREIN.
        THE USE OF ANY INFORMATION PROVIDED BY US IS SOLELY AT YOUR OWN RISK.
      </p>
      <p>
        NOTHING STATED OR COMMUNICATED OR AVAILABLE THROUGH ANY OF OUR PROGRAMS
        OR SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE
        OF MEDICAL OR COUNSELING CARE. THE PRACTICE OF MEDICINE AND COUNSELING
        INCLUDES, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY, PSYCHOTHERAPY, OR
        PROVIDING HEALTH CARE TREATMENT.
      </p>
      <h2>9. Termination</h2>
      <p>
        We may cancel or terminate your right to use the Site or any part of the
        Site at any time without notice. In the event of cancellation or
        termination, you are no longer authorized to access the part of the Site
        affected by such cancellation or termination. The restrictions imposed
        on you with respect to material downloaded from the Site, and the
        disclaimers and limitations of liabilities set forth in these Terms of
        Service, shall survive.
      </p>
      <h2>10. Refund Policy</h2>
      <p>Physical Products</p>
      <p>
        LA King LLC offers a “right of return” on all purchases of products that
        are physically delivered. If you are unhappy with your purchase, please
        email us at admin@laurie-anneking.com within 3 days from the purchase
        date of your physical product, and your refund will be processed
        immediately after you return the physical product. All items must be
        returned unopened in “like-new” condition. No refunds will be offered
        after 3 days.
      </p>
      <p />
      <p>Digital Products and Services</p>
      <p>
        All Private or Group programs and services, including digital courses
        and Monthly Auto-Renew Programs, are Non-Refundable. The only exception
        is the Claim Your Queendom Course which has a 7 day money back guarantee
        and refund period.
      </p>
      <p>Payment Plans</p>
      <p>
        You may choose to take advantage of one of our payment plan programs. If
        you purchase a product on payment plan, you are contractually agreeing
        to pay each monthly payment on time and to complete all payments. You
        are responsible for full payment and may not cancel.
      </p>
      <p>
        The due date for each installment is 30 days after purchase and payment
        of the first installment, and 30 days after each previous installment.
      </p>
      <p>In Person Events & Private Sessions</p>
      <p>
        All payment for live events, retreats and private coaching sessions are
        non-refundable and non-transferrable.
        {' '}
      </p>
      <p>
        LA King LLC and all related websites of LA King LLC, reserve the right
        to revise the terms of this Refund Policy. Any changes made will apply
        to all Programs, Products, Services, and Memberships created or renewed
        after the date such change was implemented.
      </p>
      <p />
      <h2>11. Deposits</h2>
      <p>ALL DEPOSITS ARE NON-REFUNDABLE</p>
      <h2>12. Shipping Policy</h2>
      <p>​</p>
      <p>
        All products provided by LA King LLC are digital products or in-person
        services ONLY. There are currently no physical products being shipped.
        LA King LLC reserves the right from time to time to update the website’s
        Shipping Policy to reflect any changes in the available product line.
      </p>
      <p />
      <h2>13. Confidentiality</h2>
      <p>
        You agree not to disclose information you obtain from us and or from our
        clients, advertisers, suppliers and forum members. All information
        submitted to by an end-user customer pursuant to a Program is
        proprietary information of LA King LLC. Such customer information is
        confidential and may not be disclosed. Publisher agrees not to
        reproduce, disseminate, sell, distribute or commercially exploit any
        such proprietary information in any manner.
      </p>
      <p>
        This site has security measures in place to protect the loss, misuse,
        and alteration of the information under our control. All Shopping Cart
        transactions are conducted on a secure server, which means all
        information you enter will be encrypted. Your personal information is
        only available to you once you log into our Site with your valid
        Customer ID and Password.
      </p>
      <h2>14. Other</h2>
      <p>
        The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
        recourse for copyright owners who believe that material appearing on the
        Internet infringes their rights under the U.S. copyright law. If you
        believe in good faith that materials hosted by LA King LLC infringes
        your copyright, you, or your agent may send to LA King LLC a notice
        requesting that the material be removed or access to it be blocked. Any
        notification by a copyright owner or a person authorized to act on its
        behalf that fails to comply with requirements of the DMCA shall not be
        considered sufficient notice and shall not be deemed to confer upon LA
        King LLC actual knowledge of facts or circumstances from which
        infringing material or acts are evident. If you believe in good faith
        that a notice of copyright infringement has been wrongly filed against
        you, the DMCA permits you to send to LA King LLC a counter-notice. All
        notices and counter notices must meet the then current statutory
        requirements imposed by the DMCA; see http://www.loc.gov/copyright for
        details. LA King LLC’s Copyright Agent for notice of claims of copyright
        infringement or counter notices can be reached as follows:
        admin@laurie-anneking.com.
      </p>
      <p>
        This Agreement shall be binding upon and inure to the benefit of LA King
        LLC and our respective assigns, successors, heirs, and legal
        representatives. Neither this Agreement nor any rights hereunder may be
        assigned without the prior written consent of LA King LLC.
        Notwithstanding the foregoing, all rights and obligations under this
        Agreement may be freely assigned by LA King LLC to any affiliated entity
        or any of its wholly owned subsidiaries.
      </p>
      <p>Dispute Resolution:</p>
      <p>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of the State of California and any dispute shall be subject to
        binding arbitration in Alameda, California. If any provision of this
        agreement shall be unlawful, void or for any reason unenforceable, then
        that provision shall be deemed severable from this agreement and shall
        not affect the validity and enforceability of any remaining provisions.
      </p>
      <p>Class Action Waiver</p>
      <p>
        You may only resolve disputes with us on an individual basis, and may
        not bring a claim as a plaintiff or a class member in a class,
        consolidated, or representative action. Class arbitrations, class
        actions, private attorney general actions, and consolidation with other
        arbitrations aren’t allowed.
      </p>
      <p>
        The arbitrator may not consolidate more than one person’s claims, and
        may not otherwise preside over any form of a class or representative
        proceeding or claims (such as a class action, consolidated action or
        private attorney general action) unless all relevant parties
        specifically agree to do so following initiation of the arbitration.
      </p>
      <p>Severability</p>
      <p>
        If any clause within this Provision (other than the Class Action Waiver
        clause above) is found to be illegal or unenforceable, that clause will
        be severed from this Provision, and the remainder of this Provision will
        be given full force and effect. If the Class Action Waiver clause is
        found to be illegal or unenforceable, this entire Provision will be
        unenforceable and the dispute will be decided by a court.
      </p>
    </Container>
  );
}
